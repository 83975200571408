.bg-newsletter {
  background: radial-gradient(29.64% 120.37% at 20.36% 17.97%, rgba(236, 43, 43, 0.4) 0%, rgba(0, 0, 0, 0) 100%), #501818;
}

.bg-menu {
  background: linear-gradient(90.02deg, rgba(25, 27, 31, 0) 0.01%, #191B1F 50%, rgba(25, 27, 31, 0) 99.98%);
}

.image-sign-up {
  height: 83px;
  width: 83px;
}

.send-email {
  @apply pt-4;
}

.sign-up-newsletter {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-white to-light-red text-4xl xl:text-5xl font-bold;
  line-height: 42px;
}

@media (min-width: 1280px) {
  .image-sign-up {
    height: 130px;
    width: 130px;
  }
  .sign-up-newsletter {
    line-height: 64px;
  }
  .send-email {
    @apply pt-0;
    width: 600px;
  }
}
